import React, { useRef, useState } from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"
import data from "../../../components/_V2/RegistrationSuccess/data"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";

interface Props {
	className?: string
	item: {
		icon: JSX.Element
		title: string | JSX.Element
		description: string | JSX.Element
	}
}

export default function RequisitesItem(props: Props) {
	const localizationContext = useLocalizationContext()
	const [isCopied, setIsCopied] = useState(false)
	const onClickCopy = () => {
		navigator.clipboard
			.writeText(descriptionRef.current.textContent)
			.then(() => {
				setIsCopied(true)

				setTimeout(() => {
					setIsCopied(false)
				}, 7500)
			})
	}

	const descriptionRef = useRef(null)

	return (
		<div className={classNames(styles.requisitesItem, {[styles.requisitesItemBy]: localizationContext.locale === 'ru-BY'})}>
			{props.item.icon}
			<h3 className={styles.requisitesItem__title}>{props.item.title}</h3>
			<div className={styles.requisitesItem__description}>
				<div ref={descriptionRef}>{props.item.description}</div>

				<div className={styles.requisitesItem__backdrop}>
					<button className={styles.requisitesItem__copy} onClick={onClickCopy}>
						{isCopied ? "Скопировано" : "Скопировать"}
					</button>
				</div>
			</div>
		</div>
	)
}
