import React from "react"

import classNames from "classnames"

import styles from "./styles.module.scss"
import config from "../../../config";
import MapMarker from "../../../assets/_V2/svg/MapMarker";
import markerIcon from "./assets/marker.png"

import {useLocalizationContext} from "../../../localization/useLocalizationContext";

import {
	YMap,
	YMapDefaultSchemeLayer,
	YMapDefaultFeaturesLayer, YMapComponentsProvider, YMapDefaultMarker, YMapMarker,

} from "ymap3-components";

import {LngLat} from "@yandex/ymaps3-types";
import {StaticImage} from "gatsby-plugin-image";


interface Props {
	className?: string
}

export default function ContactsMap(props: Props) {

	const localizationContext = useLocalizationContext()

	const coordinates = {
		'ru-RU': [37.569539, 55.789406] as LngLat,
		'ru-KZ': [76.891970, 43.202449] as LngLat,
		'kz-KZ': [76.891970, 43.202449] as LngLat,
		'ru-BY': [27.557483, 53.906374] as LngLat
	}

	const currentCoordinates = coordinates[localizationContext.locale]

	const location =  { center: currentCoordinates, zoom: 17 }

	return (
		<div className={classNames(props.className, styles.section)}>
			<YMapComponentsProvider apiKey={config.YANDEX_API_KEY}>
				<YMap
					location={location}
					theme={'light'}
					className={styles.map}
					mode={'raster'}
				>
					<YMapDefaultSchemeLayer />
					<YMapDefaultFeaturesLayer />
					{/*не знаю почему, но если маркер один, то он не рендерится*/}
					<YMapDefaultMarker hideOutsideViewport={false} coordinates={currentCoordinates} />
					<YMapDefaultMarker hideOutsideViewport={false} coordinates={currentCoordinates} />
				</YMap>
			</YMapComponentsProvider>
		</div>
	)
}
