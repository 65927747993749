import React from "react"
import Icon247 from "../../../assets/_V2/svg/Icon247"
import OfficeIcon from "../../../assets/_V2/svg/OfficeIcon"

import {contacts, contactsBy, contactsKz} from "../../../pages-data/_V2/common/contacts"
import styles from "./styles.module.scss"
import {Locales} from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-BY') {
		return [
			{
				icon: <Icon247 />,
				content: [
					{
						label: <span>Горячая&nbsp;линия</span>,
						value: (
							<a
								itemProp="contactPoint"
								itemScope
								itemType="http://schema.org/ContactPoint"
								href={`tel:${contactsBy.phone.value}`}
							>
								<span itemProp="telephone">{contactsBy.phone.text}</span>
							</a>
						),
						hint: (
							<>
								<span>
									<b>доб. 1</b> - техподдержка (24/7)
								</span>
								<span>
									<b>доб. 2</b> - отдел продаж
								</span>
							</>
						)
					},
					{
						label: <span>Отдел продаж</span>,
						value: (
							<a
								itemProp="contactPoint"
								itemScope
								itemType="http://schema.org/ContactPoint"
								href={`mailto:${contactsBy.salesEmail}`}
							>
								<span itemProp="email">{contactsBy.salesEmail}</span>
							</a>
						),
					},
					{
						label: <span>Техническая поддержка</span>,
						value: (
							<a
								itemProp="contactPoint"
								itemScope
								itemType="http://schema.org/ContactPoint"
								href={`mailto:${contactsBy.supportEmail}`}
							>
								<span itemProp="email">{contactsBy.supportEmail}</span>
							</a>
						),
					},
				],
			},
			{
				icon: <OfficeIcon />,
				content: [
					{
						label: <span>Адрес</span>,
						value: <span itemProp="address">{contactsBy.address}</span>,
					},
					{
						label: <span>График работы</span>,
						value: (
							<span
								itemScope
								itemType="https://schema.org/LocalBusiness"
								className={styles.schedule}
							>
								<span itemProp="openingHours">Пн – Пт, 09:00-18:00</span>
							</span>
						),
					},
					{
						label: '',
						value: '',
					},
				],
			},
		]
	}

	if (locale === 'kz-KZ') {
		return [
			{
				icon: <Icon247 />,
				content: [
					{
						label: <span>Тәулік бойы жұмыс істейтін жедел желі</span>,
						value: (
							<a
								itemProp="contactPoint"
								itemScope
								itemType="http://schema.org/ContactPoint"
								href={`tel:${contactsKz.phone.value}`}
							>
								<span itemProp="telephone">{contactsKz.phone.textContacts}</span>
							</a>
						),
					},
					{
						label: <span>Техникалық қолдау</span>,
						value: (
							<a
								itemProp="contactPoint"
								itemScope
								itemType="http://schema.org/ContactPoint"
								href={`mailto:${contactsKz.supportEmail}`}
							>
								<span itemProp="email">{contactsKz.supportEmail}</span>
							</a>
						),
					},
					{
						label: <span>Сату бөлімі</span>,
						value: (
							<a
								itemProp="contactPoint"
								itemScope
								itemType="http://schema.org/ContactPoint"
								href={`mailto:${contactsKz.salesEmailContacts}`}
							>
								<span itemProp="email">{contactsKz.salesEmailContacts}</span>
							</a>
						),
					},
				],
			},
			{
				icon: <OfficeIcon />,
				content: [
					{
						label: <span>Телефон</span>,
						value: (
							<a
								itemProp="contactPoint"
								itemScope
								itemType="http://schema.org/ContactPoint"
								href={`tel:${contactsKz.officePhone.value}`}
							>
								<span itemProp="telephone">{contactsKz.officePhone.text}</span>
							</a>
						),
					},
					{
						label: <span>Мекенжайы</span>,
						value: <span itemProp="address">{contactsKz.address}</span>,
					},
					{
						label: <span>Жұмыс кестесі</span>,
						value: (
							<span
								itemScope
								itemType="https://schema.org/LocalBusiness"
								className={styles.schedule}
							>
						<span itemProp="openingHours">Дс – Жм,  10:00-19:00</span>
					</span>
						),
					},
				],
			},
		]
	}

	if (locale === 'ru-KZ') {
		return [
			{
				icon: <Icon247 />,
				content: [
					{
						label: <span>Круглосуточная горячая&nbsp;линия</span>,
						value: (
							<a
								itemProp="contactPoint"
								itemScope
								itemType="http://schema.org/ContactPoint"
								href={`tel:${contactsKz.phone.value}`}
							>
								<span itemProp="telephone">{contactsKz.phone.textContacts}</span>
							</a>
						),
					},
					{
						label: <span>Техническая поддержка</span>,
						value: (
							<a
								itemProp="contactPoint"
								itemScope
								itemType="http://schema.org/ContactPoint"
								href={`mailto:${contactsKz.supportEmail}`}
							>
								<span itemProp="email">{contactsKz.supportEmail}</span>
							</a>
						),
					},
					{
						label: <span>Отдел продаж</span>,
						value: (
							<a
								itemProp="contactPoint"
								itemScope
								itemType="http://schema.org/ContactPoint"
								href={`mailto:${contactsKz.salesEmailContacts}`}
							>
								<span itemProp="email">{contactsKz.salesEmailContacts}</span>
							</a>
						),
					},
				],
			},
			{
				icon: <OfficeIcon />,
				content: [
					{
						label: <span>Телефон</span>,
						value: (
							<a
								itemProp="contactPoint"
								itemScope
								itemType="http://schema.org/ContactPoint"
								href={`tel:${contactsKz.officePhone.value}`}
							>
								<span itemProp="telephone">{contactsKz.officePhone.text}</span>
							</a>
						),
					},
					{
						label: <span>Адрес</span>,
						value: <span itemProp="address">{contactsKz.address}</span>,
					},
					{
						label: <span>График работы</span>,
						value: (
							<span
								itemScope
								itemType="https://schema.org/LocalBusiness"
								className={styles.schedule}
							>
						<span itemProp="openingHours">Пн – Пт, 10:00-19:00</span>
					</span>
						),
					},
				],
			},
		]
	}

	return [
		{
			icon: <Icon247 />,
			content: [
				{
					label: <span>Круглосуточная горячая&nbsp;линия</span>,
					value: (
						<a
							itemProp="contactPoint"
							itemScope
							itemType="http://schema.org/ContactPoint"
							href={`tel:${contacts.phone.value}`}
						>
							<span itemProp="telephone">{contacts.phone.text}</span>
						</a>
					),
				},
				{
					label: <span>Техническая поддержка</span>,
					value: (
						<a
							itemProp="contactPoint"
							itemScope
							itemType="http://schema.org/ContactPoint"
							href={`mailto:${contacts.supportEmail}`}
						>
							<span itemProp="email">{contacts.supportEmail}</span>
						</a>
					),
				},
				{
					label: <span>Отдел продаж</span>,
					value: (
						<a
							itemProp="contactPoint"
							itemScope
							itemType="http://schema.org/ContactPoint"
							href={`mailto:${contacts.salesEmail}`}
						>
							<span itemProp="email">{contacts.salesEmail}</span>
						</a>
					),
				},
			],
		},
		{
			icon: <OfficeIcon />,
			content: [
				{
					label: <span>Телефон</span>,
					value: (
						<a
							itemProp="contactPoint"
							itemScope
							itemType="http://schema.org/ContactPoint"
							href={`tel:${contacts.officePhone.value}`}
						>
							<span itemProp="telephone">{contacts.officePhone.text}</span>
						</a>
					),
				},
				{
					label: <span>Адрес</span>,
					value: <span itemProp="address">{contacts.address}</span>,
				},
				{
					label: <span>График работы</span>,
					value: (
						<span
							itemScope
							itemType="https://schema.org/LocalBusiness"
							className={styles.schedule}
						>
						<span itemProp="openingHours">Пн – Пт, 10:00-19:00</span>
					</span>
					),
				},
			],
		},
	]
}
