import React from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"

import  { getData } from "./data"
import RequisitesItem from "./Item"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";

interface Props {
	className?: string
}

export default function Requisites(props: Props) {
	const localizationContext = useLocalizationContext();
	return (
		<>
			<section className={classNames(styles.requisites, props.className)}>
				<div className={styles.requisites__content}>
					<h2 className={styles.requisites__title}>{getData(localizationContext.locale).title}</h2>

					<div className={classNames(styles.requisites__items, {[styles.requisites__itemsBy]: localizationContext.locale === 'ru-BY'})}>
						{getData(localizationContext.locale).items.map(item => (
							<RequisitesItem item={item} key={item.id} />
						))}
					</div>
				</div>
			</section>
		</>
	)
}
